html {
  --color-black: #000000;
  --color-black-shade1: #1d1e1e;
  --color-black-shade2: #0e1217;
  --color-white: #ffffff;
  --color-purple: #3d38d9;
  --color-purple-lite: #1f24e6;
  --color-pink: #e220ae;
  --color-pink-lite: #be095e;
  --color-purple-heart: #6d1ed4;
  --color-white-mode: #efefef;
  --color-grey-mode: #919191;
  --color-bgwhite-mode: #060710;
  --color-bggallery-mode: #000000;
  --color-midblack-mode: #ffffff;
  --color-bgwallet-mode: #06050e;
  // End Dark Mode Palletes
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}
.text-justify {
  text-align: justify;
}
.text-purple {
  color: var(--color-purple);
}
.text-pink {
  color: var(--color-pink);
}
.text-purple-heart {
  color: var(--color-purple-heart);
}
.fw-bold {
  font-weight: 900;
}
.fw-semibold {
  font-weight: 700;
}
.bg-gray {
  background-color: var(--color-grey-mode);
}
.bg-purple {
  background-color: var(--color-purple);
}
.border-dark-pink {
  border-color: var(--color-pink-lite);
}
.bg-main-gradient {
  background-image: linear-gradient(
    to left,
    #c40859,
    #c30073,
    #b40096,
    #9000bd,
    #1f24e6,
    #1f24e6
  );
}
.bg-black-shade1 {
  background-color: var(--color-black-shade1);
}
.bg-transparent {
  background-color: transparent;
}
.bg-black-shade2 {
  background-color: var(--color-black-shade2);
}
//Button Link
.main-btn,
.nav-link.main-btn {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.75em 1.75em;
  background-size: 200% auto;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  background-image: linear-gradient(
    to left,
    #c40859,
    #c30073,
    #b40096,
    #9000bd,
    #1f24e6,
    #1f24e6
  );
  border: 0px solid transparent;
  border-radius: 0.4rem;
  transition: 0.5s;
  &:hover {
    background-position: right center;
  }
}

.btn-main-outline {
  background-color: transparent;
  color: var(--color-white);
  border-color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 31px;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: var(--color-white) !important;
    border-color: var(--color-purple-lite);
  }
}

.char-btn,
.nav-link.char-btn {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.75em 1em;
  background-size: 200% auto;
  color: var(--color-white);
  font-size: 13px;
  font-weight: 500;
  background-image: linear-gradient(to left, #1f24e6, #1f24e6);
  border: 1px solid #04189f;
  border-radius: 0.4rem;
  transition: 0.7s;
  &:hover,
  &:focus,
  &:active.bg-black-shade1,
  &.active {
    background-image: linear-gradient(
      to left,
      #c40859,
      #c30073,
      #b40096,
      #9000bd,
      #1f24e6,
      #1f24e6
    );
    background-position: right center;
    border: 1px solid #1f24e6;
  }
}
.char-btn-pink,
.nav-link.char-btn-pink {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.75em 1em;
  background-size: 200% auto;
  color: var(--color-white);
  font-size: 13px;
  font-weight: 500;
  background-color: var(--color-pink);
  border: 1px solid #04189f;
  border-radius: 0.4rem;
  transition: 0.7s;
  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-pink);
    background-position: right center;
    border: 1px solid #1f24e6;
  }
}
.char-btn-blue,
.nav-link.char-btn-blue {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.75em 1em;
  background-size: 200% auto;
  color: var(--color-white);
  font-size: 13px;
  font-weight: 500;
  background-color: var(--color-purple-lite);
  border: 1px solid #1f24e6;
  border-radius: 0.4rem;
  transition: 0.7s;
  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-purple-lite);
    background-position: right center;
    border: 1px solid #1f24e6;
    // background-color: rgba(31, 36, 230, 1);
  }
}
@media (max-width: 567.98px) {
  .char-btn,
  .nav-link.char-btn,
  .char-btn-pink,
  .nav-link.char-btn-pink,
  .char-btn-blue,
  .nav-link.char-btn-blue {
    padding: 0.75em 0.85em;
    font-size: 12px;
  }
}
/* ============================================== Font Sizes ===================================================== */

.fs-8 {
  font-size: 8px;
}
.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-25 {
  font-size: 25px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}
.fs-29 {
  font-size: 29px;
}
.fs-30 {
  font-size: 30px;
}
.fs-31 {
  font-size: 31px;
}
.fs-32 {
  font-size: 32px;
}

.font-Rajdhani {
  font-family: "Montserrat", sans-serif;
}

.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
.w-60 {
  width: 60px;
}
